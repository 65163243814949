<template>
    <div class="reportPreInfo">
        <div class="filter-box">
            <div class="filter-bnts ">
                <div class="inline-block select">
                    <el-select v-model="showType" default-first-option>
                        <el-option :value="0" label="按明细显示"></el-option>
                        <el-option :value="1" label="按汇总显示"></el-option>
                        <el-option :value="2" label="实时桌态"></el-option>
                        <el-option :value="3" label="按订单显示"></el-option>
                    </el-select>
                </div>
                <div class="inline-block" v-if="showType!=2">
                    <div class="flex-box business">
                        <div class="lable-txt">营业日期：</div>
                        <div class="from-input" >
                            <el-date-picker class="from-date"
                                :clearable="false"
                                :editable="false"
                                v-model="businessHours"
                                type="date"
                                placeholder=""
                                :default-value="new Date()">
                            </el-date-picker>
                        </div>
                    </div>
                </div>
                
                <div class="inline-block" v-if="showType!=2">
                    <div class="flex-box eat">
                        <div class="lable-txt">时段:</div>
                        <div class="from-input" >
                            <el-select v-model="salesValue" placeholder="全天" >
                                <el-option value="" label="全天"></el-option>
                                <el-option
                                    v-for="item in eatPhases"
                                    :key="item.EAT_PHASEID"
                                    :label="item.EAT_PHASENAME"
                                    :value="item.EAT_PHASEID">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="inline-block" v-if="showType!=2">
                    <div class="flex-box">
                        <div class="lable-txt">台号:</div>
                        <div class="from-input search-input"><input type="text" v-model="selectContent"  placeholder="台号检索" /></div>
                    </div>
                </div>
                
                <div class="inline-block" v-if="showType!=2">
                    <div class="search-flex">
                        <div class="bnt-search" @click="ConsumeReportClick()" >查询</div>
                    </div>
                </div>
            </div>
            <div class="right-bnts"  v-if="showType!=2">
                <div class="bnt" @click="print()" v-show="showType==3"><i class="iconfont icon-dayin"></i>打印报表</div>
                <div class="bnt" @click="exportExcel()" ><i class="iconfont icon-daochu"></i>导出报表</div>
            </div>
        </div>
        <div class="content-box clearfix">
            <div class="table-box grey-table" v-show="showType==0">
                <el-table ref="ConsumetableEl" id="ConsumeTable" :data="tableList"  style="width: 100%;height: 100%;" border show-summary 
                :summary-method="getSummaries" 
                :span-method="ConsumeOrderSpanMethod"
                class="el-table--scrollable-y"
                :row-class-name="tableRowClass"
                v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                    <el-table-column prop="Eat_PreID" label="预定单号" min-width="100"></el-table-column>
                    <el-table-column prop="Eat_Phase" label="时段" min-width="60"></el-table-column>
                    <el-table-column prop="Eat_DeskNum" label="桌数" min-width="80"></el-table-column>
                    <el-table-column prop="Eat_DeskNo" label="桌号" min-width="120"></el-table-column>
                    <el-table-column prop="Eat_ManNum" label="预订人数" min-width="80"></el-table-column>
                    <el-table-column prop="Eat_XFKindName" label="大类名称" min-width="100">
                        <template #default="scope">
                            <span class="lefts">{{scope.row.Eat_XFKindName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Eat_XFSmallKindName" label="小类名称" min-width="100">
                        <template #default="scope">
                            <span class="lefts">{{scope.row.Eat_XFSmallKindName}}</span>
                        </template>
                    </el-table-column> 
                    <el-table-column prop="Eat_XFCode" label="菜品编码" min-width="80">
                        <template #default="scope">
                            <span class="lefts">{{scope.row.Eat_XFCode}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Eat_XFName" label="菜品名称" min-width="100">
                        <template #default="scope">
                            <span class="lefts">{{scope.row.Eat_XFName}}</span>
                        </template>
                    </el-table-column> 
                    <el-table-column prop="Eat_XFSize" label="单位" min-width="80"></el-table-column> 
                    <el-table-column prop="Eat_Number" label="数量" min-width="80">
                        <template #default="scope">
                            <span class="rigths">{{scope.row.Eat_Number}}</span>
                        </template>
                    </el-table-column> 
                    <el-table-column prop="Eat_Remark" label="口味做法" min-width="120" ></el-table-column> 
                </el-table>
            </div>
            <div class="table-box grey-table consumeTable" v-show="showType==1">
                <el-table ref="foodtableEl" id="FoodTable" :data="foodTableList"  style="width: 100%;height: 100%;" border
                class="el-table--scrollable-y"
                v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                    <el-table-column prop="Eat_XFKindName" label="大类名称" min-width="100">
                        <template #default="scope">
                            <span class="lefts">{{scope.row.Eat_XFKindName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Eat_XFSmallKindName" label="小类名称" min-width="100">
                        <template #default="scope">
                            <span class="lefts">{{scope.row.Eat_XFSmallKindName}}</span>
                        </template>
                    </el-table-column> 
                    <el-table-column prop="Eat_XFCode" label="菜品编码" min-width="80">
                        <template #default="scope">
                            <span class="lefts">{{scope.row.Eat_XFCode}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Eat_XFName" label="菜品名称" min-width="100">
                        <template #default="scope">
                            <span class="lefts">{{scope.row.Eat_XFName}}</span>
                        </template>
                    </el-table-column> 
                    <el-table-column prop="Eat_XFSize" label="单位" min-width="80"></el-table-column> 
                    <el-table-column prop="Eat_Number" label="数量" min-width="80">
                        <template #default="scope">
                            <span class="rigths">{{scope.row.Eat_Number}}</span>
                        </template>
                    </el-table-column> 
                </el-table>
            </div>
            <div class="diningTable-box" v-if="showType==2">
                <div class="divisionlin"></div>
                <div class="filter-box clearfix">
                    <div class="filter-bnt" :class="{selected:DeskType==-1}" @click="DeskTypeClick(-1)">全部({{RoomDeskType.totalNum}})</div>
                    <div class="filter-bnt bnt-empty"  :class="{ selected: DeskType == 0 }" @click="DeskTypeClick(0)"><b class="bnt-bg"></b>空台(<span class="num">{{RoomDeskType.emptyNum}}</span>)</div>
                    <div class="filter-bnt bnt-opening" :class="{ selected: DeskType == 1 }" @click="DeskTypeClick(1)"><b class="bnt-bg"></b>开台(<span class="num">{{RoomDeskType.openingNum}}</span>)</div>
                    <div class="filter-bnt bnt-reserve" :class="{ selected: DeskType == 2 }" @click="DeskTypeClick(2)"><b class="bnt-bg"></b>预定(<span class="num">{{RoomDeskType.reserveNum}}</span>)</div>
                    <div class="filter-bnt bnt-preKnot" :class="{ selected: DeskType == 3 }" @click="DeskTypeClick(3)"><b class="bnt-bg"></b>预结(<span class="num">{{RoomDeskType.preKnotNum}}</span>)</div>
                    <div class="filter-bnt bnt-overtime" :class="{ selected: DeskType == 4 }" @click="DeskTypeClick(4)"><b class="bnt-bg"></b>超时(<span class="num">{{RoomDeskType.overtimeNum}}</span>)</div>
                    <div class="filter-bnt bnt-clean" :class="{ selected: DeskType == 5 }" @click="DeskTypeClick(5)"><b class="bnt-bg"></b>清台(<span class="num">{{RoomDeskType.cleanNum}}</span>)</div>
                </div>
                <div class="divisionlin"></div>
                <div class="filter-nav clearfix">
                    <div class="nav-box" v-mouse-scroll v-scroll-anime="{up:'nav-up',down:'nav-down',fx:'x',liClass:'.nav-li',page:'DeskNextPage',isNavFirstSticky:true}">
                        <div class="nav-li" :class="{ selected: filternav == -1 }" @click="NavigationClick(-1)" style="position: sticky;left:0">全部</div>
                        <div class="nav-li" :class="{ selected: filternav == index }" 
                            v-for="(item,index) in PosRooms" :key="item"  
                            @click="NavigationClick(index)" >
                            {{item.Room_Name}}
                        </div>
                    </div>
                    <div class="float-right page-box">
                        <span class="last-page iconfont icon-fangxiangquan-left" ref="nav-up" ></span>
                        <span class="page-number">{{DeskNextPage.pageIndex}}/{{DeskNextPage.pageSize}} 页</span>
                        <span class="next-page iconfont icon-fangxiangquan-right" ref="nav-down" ></span>
                    </div>
                </div>
                <div class="desk content clearfix" v-loading="isLoading">
                    <div class="verticalCenter" v-if="!isRendering" style="height:100%;text-align: center; ">
                        <div style="display: inline-block;vertical-align: middle;text-align: left;">
                            <el-empty description="无数据,点击重新加载" @click="ShowRoomDeskList()">
                            </el-empty>
                        </div>
                    </div>
                    <layout-rc class="scrollBox" :isVarCss="true" :row="arg.row" :col="arg.col" :marginW="10" :marginH="10" v-if="isRendering">
                        <!--0：空台 1：开台 2：预定 3：预结 4：超时 5：清台-->
                        <div class="desk-box" rc-item  
                        v-for="(PosDesks,index) in PosDesksPage" :key="index" 
                        :class="(['empty','opening','reserve','preKnot','overtime','clean'])[PosDesks.Desk_Status]">
                        <div class="top" v-if="PosDesks.OpenDeskInfo" >
                            <span v-if="PosDesks.Desk_Status!=2 && PosDesks.Desk_Status!=5">{{PosDesks.OpenDeskInfo.Person_Num}}人</span> <!-- 人数-->
                            <span v-else-if="PosDesks.Desk_Status==2" >订</span>
                            <span v-if="PosDesks.OpenDeskInfo.Eat_FoodCounts>=0 && PosDesks.Desk_Status!=2  && PosDesks.Desk_Status!=5" class="float-right" >{{Conversion(PosDesks.OpenDeskInfo.Crossed_FoodCounts)}}/{{Conversion(PosDesks.OpenDeskInfo.Eat_FoodCounts)}}</span>  <!--已划菜数量/点菜总数量-->
                            <span v-else-if="PosDesks.ReserveDeskInfo && PosDesks.Desk_Status==2" class="float-right" >{{PosDesks.ReserveDeskInfo.Cstm_Name}}</span> <!--预定客户-->
                        </div>
                        <div class="top" v-else-if="PosDesks.Desk_Status==2" >
                            <span>订</span>
                            <span v-if="PosDesks.ReserveDeskInfo?.Cstm_Name" class="float-right" >{{PosDesks.ReserveDeskInfo?.Cstm_Name}}</span>
                        </div>
                        <p class="title">
                            {{PosDesks.Desk_Name}}
                            <span v-if="PosDesks.OpenDeskInfo && PosDesks.OpenDeskInfo.Eat_UnionID">
                                <span class="munion" v-if="PosDesks.OpenDeskInfo.Eat_UnionID!='' && PosDesks.OpenDeskInfo.Eat_UnionID==PosDesks.OpenDeskInfo.Eat_AutoID">
                                联{{Unionindex(PosDesks.OpenDeskInfo.Eat_AutoID,PosDesks.OpenDeskInfo.Eat_UnionID,1)}}
                                </span>
                                <span class="union" v-else-if="PosDesks.OpenDeskInfo.Eat_UnionID!='' && PosDesks.OpenDeskInfo.Eat_UnionID!=PosDesks.OpenDeskInfo.Eat_AutoID">
                                联{{Unionindex(PosDesks.OpenDeskInfo.Eat_AutoID,PosDesks.OpenDeskInfo.Eat_UnionID,2)}}
                                </span>
                            </span>
                        </p>
                        <div class="bottom" v-if="PosDesks.OpenDeskInfo" >
                            <span v-if="PosDesks.Desk_Status!=2 && PosDesks.Desk_Status!=5" >{{DifferenceTime(PosDesks.OpenDeskInfo.OpenDesk_Time)}}</span> <!--开台时间-->
                            <span v-else-if="PosDesks.ReserveDeskInfo && PosDesks.Desk_Status==2 " >{{PosDesks.ReserveDeskInfo.Mobile}}</span> <!--预定手机号-->
                            <span v-if="PosDesks.OpenDeskInfo.Eat_CheckMoney && PosDesks.Desk_Status!=2  && PosDesks.Desk_Status!=5 " class="float-right">￥{{PosDesks.OpenDeskInfo.Eat_CheckMoney}}</span> <!--点餐总金额-->
                            <span v-else-if="PosDesks.ReserveDeskInfo && PosDesks.Desk_Status==2" class="float-right" >{{PosDesks.ReserveDeskInfo.Reserve_PersonNum}}人</span>
                        </div>
                        <div class="bottom" v-else-if="PosDesks.Desk_Status==2" >
                            <span v-if="PosDesks.ReserveDeskInfo?.Mobile" >{{PosDesks.ReserveDeskInfo?.Mobile.replace(/^(.{3}).*(.{4})$/,'$1****$2')}}</span>
                            <span class="float-right" v-if="PosDesks.ReserveDeskInfo">{{PosDesks.ReserveDeskInfo.Reserve_PersonNum}}人</span>
                        </div>
                        <i class="iconfont icon-suo" v-if="PosDesks.Is_Desk_Locked"></i>
                        </div>
                    </layout-rc>
                </div>
                <div class="footer clearfix">
                    <div class="deskbottom" >
                        <div class="float-left search" >
                            <div class="DeskAuto" @click="showKeyBoard()" @mousedown="(e)=>e.preventDefault()">
                                <span class="iconfont icon-jianpan"></span>
                            </div>
                            <input type="text" placeholder="桌号检索" v-model="DeskAutoSelce" ref="searchBox" @blur="searchInputBlur"/>
                        </div>
                        <div class="float-right page-box-bottom">
                            <page-turning class="last-page iconfont icon-fangxiangquan-left" type="up" v-model="deskPageIndex" :pageCount="Totalpages"></page-turning>
                            <span class="page-number">{{ deskPageIndex }}/{{ Totalpages }}页</span>
                            <page-turning class="next-page iconfont icon-fangxiangquan-right" v-model="deskPageIndex" :pageCount="Totalpages"></page-turning>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-box grey-table" v-show="showType==3">
                <el-table ref="orderTableEl" :data="printTablePage"  style="width: 100%;height:100%;" border 
                    show-summary 
                    :summary-method="table3Summary" 
                    class="el-table--scrollable-y"
                    highlight-current-row
                    @selection-change="selectTable"
                    v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                    <el-table-column label="选择" type="selection" width="40" :selectable="talbeSelec"></el-table-column>
                    <el-table-column prop="Rpt_Date" label="营业日期" width="80" :formatter="shortDateFormat"></el-table-column>
                    <el-table-column prop="YD_OrderNo" label="预定单号" min-width="130"></el-table-column>
                    <el-table-column prop="Eat_PhaseName" label="时段" min-width="60"></el-table-column>
                    <el-table-column prop="DeskNum" label="桌数" min-width="60" data-format="number"></el-table-column>
                    <el-table-column prop="DeskName" label="桌号" min-width="120"></el-table-column>
                    <el-table-column prop="ManNum" label="预订人数" min-width="60" data-format="number"></el-table-column>
                    <el-table-column prop="Eat_YDYY" label="营销员" min-width="80"></el-table-column>
                    <el-table-column prop="IS_Food" label="是否排菜" min-width="60" :formatter="(row, column, cellValue)=>cellValue?'是':'否'"></el-table-column>
                    <el-table-column prop="Eat_Name" label="预订人" min-width="80"></el-table-column>
                    <el-table-column prop="Eat_PreTime" label="预订时间" width="130" :formatter="dateFormat"></el-table-column>
                    <el-table-column prop="Remark" label="备注" min-width="100"></el-table-column>
                </el-table>
            </div>
            <table-page-btn class="fy" v-show="showType==3"
                    :default-index="currentPage" 
                    @current-change="(index)=>currentPage=index"
                    :data="printTableList"
                    @page-change="(list)=>printTablePage=list" 
                    :pageSizes="[20,30,50,100]"
                    :pageSize="printPagesize"
                    @size-change="(size)=>printPagesize=size"></table-page-btn>
        </div>
        <!--权限控制-->
        <modal-load :isShow="checkShow">
            <check-user-button-model :isShow="checkShow" v-on:closeModel="checkShow=false" :checkBtnlist="checkBtnlist" @checkbtnReturn="checkbtnReturn" ></check-user-button-model>
        </modal-load>
        <analog-key-board ref="keyBoard"></analog-key-board>
     </div>
</template>

<script>
import { NumberConversion,differenceTime } from '../../../../../common'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import {newGuid} from '../../../../../common'
import checkUserButtonModel from '../../../checkUserRightModel/checkUserButtonModel.vue'
/**全局初始化数据 */
let _data={
    arg:{row:4,col:6},
    /**一页多少条桌台数据 */
    deskPageSize:4*6,
};
/**预订排菜报表 */
export default {
    components:{checkUserButtonModel},
    name:'reportPreInfo',
    data(){
        let globalSysSetting= this.$cacheData?.globalVariable?.GlobalSysSetting;
        if(globalSysSetting){
            _data.deskPageSize = globalSysSetting.DeskMap_X * globalSysSetting.DeskMap_Y;
            Object.assign(_data.arg,{row:globalSysSetting.DeskMap_X,col:globalSysSetting.DeskMap_Y});
            _data.RunCheckOutToCleanDesk = globalSysSetting.RunCheckOutToCleanDesk
        }
        let printPagesize= this.$cacheData.get("reportPreInfo_printPagesize")||20;
        return {
            //显示类型 0按明细显示 1按汇总显示 2实时桌态
            showType:0,
            //营业日期
            businessHours:new Date(),
            //时段数据
            eatPhases:null,
            //时段
            salesValue:'',
            selectContent:'', /**搜索查询条件 */

            userInfo:'',
            /**表格数据 */ 
            tableList:[],
            foodTableList:[],
            /**按订单显示 */
            printTableList:[],
            /**按订单 分页显示 */
            printTablePage:[],
            printPagesize:printPagesize,
            currentPage:1,
            /**总计报表 */
            PayDetailTotalList:[],
           
            checkShow:false,
            /**权限验证临时保存ID和操作员 */
            tempUserId:'',
            tempOperatorName:'',
            checkBtnlist:{},

            //是否加载数据中
            isLoading:false,
            //状态筛选
            DeskType:-1,
             /**餐厅页码 */
            DeskNextPage:{
                pageIndex:1,
                pageSize:1
            },
            deskPageSize:_data.deskPageSize,//一页多少条桌台数据
            /**设置 固定行数 与 固定列数  */
            arg:_data.arg,
            //桌台全部数据
            DeskActiveMapData:{},
            //桌台检索
            DeskAutoSelce:'',
            //桌台当前页
            deskPageIndex:1,
            filternav:-1,
            isRendering:false,
            /**联台展示编号 */
            MuList:[],
            Muindex:0,
            /**按订单显示 勾选的数据 */
            checkTables:[],
        }
    },
    watch:{
        printPagesize(newVal){
            this.$cacheData.set("reportPreInfo_printPagesize",newVal);
        },
        DeskAutoSelce(){
            if(this.DeskAutoSelce!=''){
                this.filternav=-1;
                this.DeskType=-1;
                this.deskPageIndex=1;
            }
        },
        showType(){
            if(this.showType==2){
                this.ShowRoomDeskList();
            }
        }
    },
    computed:{
        //桌台状态 数据数量
        RoomDeskType(){
            let data={
                totalNum:this.posDesks?.length||0,//总数量
                emptyNum:0,//空台
                openingNum:0,//开台
                reserveNum:0,//预定
                preKnotNum:0,//预结
                overtimeNum:0,//超时
                cleanNum:0//清台
            }
            this.posDesks.forEach(it=>{
                if(it.Desk_Status==0){
                    data.emptyNum++;
                }else if(it.Desk_Status==1){
                    data.openingNum++;
                }else if(it.Desk_Status==2){
                    data.reserveNum++;
                }else if(it.Desk_Status==3){
                    data.preKnotNum++;
                }else if(it.Desk_Status==4){
                    data.overtimeNum++;
                }else if(it.Desk_Status==5){
                    data.cleanNum++;
                }
            });
            return data;
        },
        //桌台分类数据
        PosRooms(){
            return this.DeskActiveMapData?.data?.PosRooms||[];
        },
        //桌台数据
        posDesks(){
            if(this.filternav>=0){
                return this.PosRooms[this.filternav]?.PosDesks||[];
            }
            let _allDesk=[];
            (this.PosRooms||[]).forEach((room)=>{
                _allDesk=_allDesk.concat(room.PosDesks);
            })
            return _allDesk;
        },
        //桌台过滤数据
        PosDesksFilter(){
            if(this.DeskAutoSelce!=''){
                let searchTxt=this.DeskAutoSelce.toLowerCase();
                return this.posDesks.filter((item)=>{
                    return (item.Desk_Code||"").toLowerCase().indexOf(searchTxt)>=0 || (item.Desk_Name||"").toLowerCase().indexOf(searchTxt)>=0;
                });
            }else{
                //桌台类型 DeskType
                if(this.DeskType!=-1){
                    return this.posDesks.filter((item)=>item.Desk_Status==this.DeskType);
                }
            }
            return this.posDesks;
        },
        //分页数据
        PosDesksPage(){
            return this.PosDesksFilter.slice((this.deskPageIndex-1) * this.deskPageSize, this.deskPageIndex * this.deskPageSize);
        },
        Totalpages(){
            return Math.ceil(this.PosDesksFilter.length/this.deskPageSize)||1;
        },
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
        this.businessHours=this.userInfo.Rpt_Date
      
        /**基础配置 */
        this.$cacheData.DeskPosconfigure().then((d)=>{
            Object.assign(_data.arg,{row:d.DeskMap_X,col:d.DeskMap_Y});
            this.deskPageSize=_data.deskPageSize = d.DeskMap_X * d.DeskMap_Y
        }).catch((e)=>{
            this.$message.error('加载基础配置失败：'+e.message);
            console.log('加载基础配置失败：',e);
        })
        //时段数据
        this.$cacheData.EatPhase().then((d)=>{
            if(d){
                this.eatPhases=d;
            }
        }).catch((e)=>{
            this.$message.error('加载时段数据失败：'+e.message);
            console.log('加载时段数据失败：'+e);
        })
    },
    methods:{
         /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /*时间格式 */
        dateFormat(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd hh:mm:ss');
        },
        /*时间格式 */
        shortDateFormat(row, column, cellValue){
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd');
        },
        /**数字转换 */
        Conversion(num){
            return NumberConversion(num)
        },
        tableRowClass({row, rowIndex}) {
            if ((rowIndex + 1) % 2 === 0) {
                return 'success-row';
            }
            return '';
        },
        //获取开台时间和当前时间的时间差
        DifferenceTime(beginTime) {
            let _time =differenceTime(beginTime);
            if(_time=='-1:-1'){
                _time='0:0'
            }
            return _time;
        },
        Unionindex(AutoID,UnionID,type){
            if(type==1){
                let types=0
                if(this.MuList){
                    this.MuList.map(item=>{
                        if(item.AutoID==AutoID){
                            types=1
                        }
                    })
                }
                if(types==0){
                this.Muindex=this.Muindex+1
                let sub={index:this.Muindex,AutoID:AutoID,UnionID:UnionID}
                this.MuList.push(sub);
                }
            }
            let retMuindex=0
                if(this.MuList){
                this.MuList.map(item=>{
                    if(item.AutoID==UnionID){
                    retMuindex = item.index
                    }
                })
            }
            return retMuindex
        },
        //加载桌台数据
        ShowRoomDeskList(){
            this.isLoading=true;
            this.$cacheData.DeskActiveMap().then(d=>{
                this.isLoading=false;
                this.DeskActiveMapData=d;
                this.isRendering = true;
            }).catch((e)=>{ 
                this.isLoading=false;
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        DeskTypeClick(index){
            this.deskPageIndex=1;
            this.DeskType=index;
            this.DeskAutoSelce="";
        },
        NavigationClick(index){
            this.filternav=index;
            this.DeskAutoSelce="";
        },
        showKeyBoard(){
            if(this.$refs.keyBoard.isOpen){
                this.$refs.keyBoard.close();
            }else{
                this.$refs.keyBoard.show(this.$refs.searchBox);
                this.$refs.searchBox.focus();
            }
        
        },
        searchInputBlur(){
            this.$refs.keyBoard.close();
        },
        /**合计列表 */
        getSummaries(param) {
            const { columns, data } = param
            const sums = []
            columns.forEach((column, index) => {
                if (index === 0) { // 只找第一列放合计
                    sums[index] = '合计：'
                    return
                }else if (index===2 || index===10) {
                    let PreID=''
                    const values = data.map(item =>{
                        if(index===2){
                            if(PreID==''){
                                PreID=item.Eat_PreID
                                return Number(item[column.property])
                            }
                            if(item.Eat_PreID!=PreID){
                                PreID = item.Eat_PreID
                                return Number(item[column.property])
                            }
                        }else{
                            return Number(item[column.property])
                        }
                    })
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr)
                            if (!isNaN(value)) {
                                return  Number(Number(prev)+Number(curr)).toFixed(2)
                            } else {
                                return  Number(prev).toFixed(2)
                            }
                        },0)
                    } else {
                        sums[index] = 'N/A'
                    }
                } else {
                    sums[index] = '--'
                }
            })
            return sums
        },
        /**合计 按订单显示*/
        table3Summary({ columns }){
            let total={
                DeskNum:0,
                ManNum:0
            };
            this.printTableList?.forEach(it=>{
                total.DeskNum+=it.DeskNum;
                total.ManNum+=it.ManNum;
            })

            return columns.map((column,index)=>{
                if(index==1){
                    return "合计"
                }else if(column.property=="DeskNum" || column.property=="ManNum"){
                    return parseFloat(total[column.property].toFixed(2)); 
                }
            })
        },
        ConsumeOrderSpanMethod({row, column, rowIndex, columnIndex}){
            if(row && columnIndex>=0 && columnIndex<=4){
                let data=this.tableList;
                if(rowIndex>0){
                    if(data[rowIndex-1].Eat_PreID==row.Eat_PreID){
                        return [0, 0];
                    }
                }
                let count=1;
                for(let i=rowIndex+1;i<data.length;i++){
                    if(data[i].Eat_PreID!=row.Eat_PreID){
                        break;
                    }
                    count++;
                }
                return [count,1];
            }
        },
         /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            try {
                let ex1Data=this.$excelCommon.addElTableToAll(this.$refs.ConsumetableEl,this.tableList)
                let ws1=this.$excelCommon.addSheetCell(ex1Data);

                let ex2Data=this.$excelCommon.addElTableToAll(this.$refs.foodtableEl,this.foodTableList)
                let ws2=this.$excelCommon.addSheetCell(ex2Data);

                let ex3Data=this.$excelCommon.addElTableToAll(this.$refs.orderTableEl,this.printTableList)
                let ws3=this.$excelCommon.addSheetCell(ex3Data);
                var workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook,ws1,"明细报表");
                XLSX.utils.book_append_sheet(workbook, ws2, "汇总报表");
                XLSX.utils.book_append_sheet(workbook, ws3, "订单报表");
                var wbOut = XLSX.write(workbook, {
                    bookType: "xlsx",
                    bookSST: true,
                    type: "array"
                });
                FileSaver.saveAs(new Blob([wbOut], { type: "application/octet-stream" }),"预定排菜报表.xlsx");
            }catch(e){
                console.log(e, wbOut);
            }
        },
        /**查询 */
        ConsumeReportClick(){
            let time = JSON.parse(JSON.stringify(this.businessHours));
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Rpt_Date:new Date(this.businessHours).Format("yyyy-MM-dd"), //营业日期
                Eat_PhaseID:this.salesValue, //时段ID
                Eat_DeskNo:this.selectContent, //台号
            }
            if(this.tempUserId!='' && this.tempOperatorName!=''){
                param.User_ID = this.tempUserId
                param.Operator_Name = this.Operator_Name
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.ReserveConsumeReport",param,false).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    console.log(d)
                    this.tableList=[]
                    this.foodTableList=[]
                    this.tableList=d.ResponseBody?.ConsumeList;
                    this.foodTableList=d.ResponseBody?.FoodList;
                    this.printTableList=d.ResponseBody?.PrintList;
                }else if(d.ResponseHeader.ResultCode==3003){
                    /**权限不足,去验证保存权限 */
                    this.checkBtnlist = Object.assign(this.checkBtnlist,{ProjectItem_Key:d.ResponseHeader.Msg_Reserve})
                    this.checkShow = true;
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.tableList=[];
                this.printTableList=[];
                this.foodTableList=[];
                loading.close();
                this.$message.error(e);
                console.log(e);
            })
        },
        //勾选的数据
        selectTable(currentRow){
            this.checkTables=currentRow;
        },
        /**判断 是否可选 */
        talbeSelec(row){
            if(this.userInfo?.Rpt_Date){
                let time=new Date(this.userInfo.Rpt_Date);
                let time2=new Date(new Date(row.Rpt_Date).Format("yyyy-MM-dd"));
                return time<=time2;
            }
            return false;
        },
        /**打印 */
        print(){

            let orderIds=this.checkTables?.map(it=>it.Eat_PreAutoID);
            if(!(orderIds?.length>0)){
                this.$message.warning("请先勾选需要打印的数据!");
                return;
            }
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Rpt_Date:new Date(this.businessHours).Format("yyyy-MM-dd"), //营业日期
                ReserveOrderIds:orderIds, //预订单ID列表
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.ReserveOrderPrint",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc);
                    return;
                }
                if(d.ResponseBody?.length>0){
                    this.$webBrowser.posPrint(d.ResponseBody);
                    this.$message.success("已发送打印指令");
                }else{
                    this.$message.warning("未获取到可打印的数据信息,请检查预排菜打印设置是否有关联对应菜品");
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error(e);
                console.log(e);
            })
        },
    }
}
</script>

<style lang="scss" >
@import './reportPreInfo.scss'
</style>